import React, { lazy } from 'react';
import { RouteInfoType, RouteModuleType, RouteType } from '../../types/types';
import SidebarIcon, { SidebarIconType } from '../../assets/sidebar/SidebarIcon';
import { AccountRole } from '../../services/types';
import LayoutWithMenu from '../../layouts/LayoutWithMenu';

const Flats = lazy(() => import('../maintainer/Flats/index'));
const GroupSets = lazy(() => import('../maintainer/GroupSets/index'));
const Subscribers = lazy(() => import('../maintainer/Subscribers/index'));
const Settings = lazy(() => import('../maintainer/Settings/index'));

const maintainerRoutes = {
    id: 'maintainer',
    path: '/wise-sip',
    name: 'fd',
    hide: true,
    roles: [AccountRole.root],
    component: <LayoutWithMenu />,
    routes: [
        {
            path: '3434',
            header: 'Настройка звонков',
            type: RouteType.section,
            roles: [AccountRole.root],
            childrenModule: [RouteModuleType.invoices],
        },

        {
            path: '/wise-sip/flats',
            icon: <SidebarIcon type={SidebarIconType.intercom} />,
            name: 'Квартиры',
            component: <Flats />,
            type: RouteType.route,
            roles: [AccountRole.root],
            module: RouteModuleType.invoices,
        },
        {
            path: '/wise-sip/sip-accounts',
            icon: <SidebarIcon type={SidebarIconType.phone} />,
            name: 'SIP-аккаунты',
            component: <Subscribers />,
            type: RouteType.route,
            roles: [AccountRole.root],
            module: RouteModuleType.invoices,
        },
        {
            path: '343433',
            header: 'Дополнительно',
            type: RouteType.section,
            roles: [AccountRole.root],
            childrenModule: [RouteModuleType.other],
        },
        // {
        //     path: '/wise-sip/accounts',
        //     icon: <SidebarIcon type={SidebarIconType.users} />,
        //     name: 'Пользователи',
        //     component: <GroupSets />,
        //     type: RouteType.route,
        //     roles: [AccountRole.root],
        //     module: RouteModuleType.other,
        // },
        {
            path: '/wise-sip/group-sets',
            icon: <SidebarIcon type={SidebarIconType.buildings} />,
            name: 'Дома и подъезды',
            component: <GroupSets />,
            type: RouteType.route,
            roles: [AccountRole.root],
            module: RouteModuleType.other,
        },
        {
            path: '/wise-sip/settings',
            icon: <SidebarIcon type={SidebarIconType.settings} />,
            name: 'Настройки',
            component: <Settings />,
            type: RouteType.route,
            roles: [AccountRole.root],
            module: RouteModuleType.other,
        },
        // {
        //     path: `/wise-sip/history`,
        //     icon: <SidebarIcon type={SidebarIconType.clock} />,
        //     name: 'История вызовов',
        //     component: <GroupSets />,
        //     type: RouteType.route,
        //     roles: [AccountRole.root],
        //     module: RouteModuleType.other,
        // },
    ],
} as RouteInfoType;

export default maintainerRoutes;
