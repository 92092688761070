import intercom from './intercom.png';
import phone from './phone.png';

import users from './users.png';
import buildings from './buildings.png';
import clock from './clock.png';
import settings from './settings.png';

import React from 'react';

export enum SidebarIconType {
    // calls
    intercom,
    phone,

    // other
    users,
    buildings,
    clock,
    settings,
}

const map = {
    [SidebarIconType.intercom]: intercom,
    [SidebarIconType.phone]: phone,

    [SidebarIconType.users]: users,
    [SidebarIconType.buildings]: buildings,
    [SidebarIconType.clock]: clock,
    [SidebarIconType.settings]: settings,
};

interface IProps {
    type: SidebarIconType;
    opacity?: number;
}
const SidebarIcon = (props: IProps) => {
    const src = map[props.type];

    return <img src={src} alt='' />;
};
export default SidebarIcon;
