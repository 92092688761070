export enum ResponseStatus {
    finished = 'finished',
    failed = 'failed',
}

export interface GeneralResponse<T> {
    status: ResponseStatus;
    message: string;
    result: T;
}

export interface SignInRequest {
    login: string;
    password: string;
}

export interface CreateGroupSet {
    title: string;
    token: string | null
}

export interface UpdateGroupSet {
    title: string;
    token: string | null
}

export interface GetGroupSetsRequest {
    limit: number;
    skip: number;
}

export interface GroupSets {
    group_sets: GroupSet[];
    total: number;
}

export interface GroupSet {
    id: number;
    title: string;
    token: string | null
}

export interface GetFlatsByGroupSetRequest {
    limit: number;
    skip: number;
    group_set_id: number;
}

export interface Flats {
    groups: Flat[];
    total: number;
}

export interface Flat {
    id: number;
    sip_id: string;
    title: string;
    group_set_id: number;
}

export interface CreateFlatRequest {
    sip_id: string;
    title: string;
    group_set_id: number;
}

export interface UpdateFlatRequest {
    sip_id: string;
    title: string;
    group_set_id: number;
}

export interface GetSubscribersByGroupSetIdRequest {
    skip: number;
    limit: number;
    group_set_id: number;
}

export interface CreateSubscriberRequest {
    username: string;
    domain: string;
    password: string;
    account_type: SipAccountType;
    dialplan: DialplanType;
    group_id: number | null;
    group_set_id: number | null;
}

export interface UpdateSubscriberRequest {
    username: string;
    domain: string;
    password: string;
    account_type: SipAccountType;
    dialplan: DialplanType;
    group_id: number | null;
    group_set_id: number | null;
}

export interface Subscribers {
    subscribers: Subscriber[];
    total: number;
}

export interface Subscriber {
    id: number;
    username: string;
    domain: string;
    password: string;
    ha1: string;
    ha1b: string;
    account_type: SipAccountType;
    dialplan: DialplanType;
    group_id: number | null;
    group_set_id: number | null;
    is_online: boolean;
    expiresAt: Date | null;
    ip: string | null;
}

export enum SipAccountType{
    app_webrtc = 'app:webrtc',
    app_udp = 'app:udp',
    udp_internal = 'udp:internal',
    udp_external = 'udp:external',
}

export const SipAccount: { [key in SipAccountType]?: string } = {
   [SipAccountType.app_webrtc]: 'Приложение, WebRTC (Wise)',
   [SipAccountType.app_udp]: 'Приложение, UDP',
   [SipAccountType.udp_internal]: 'UDP-клиент',
   [SipAccountType.udp_external]: 'UDP-клиент (NAT)',
};

export type DialplanType = 'house' | 'default';

export const Dialplan: { [key in DialplanType]?: string } = {
    house: 'Подъезд',
    default: 'Квартира',
};
